import http from "./http";
import { API_BACKEND } from "../utils/api";
const apiEndpoint = "/event";

/*
 * Get Event by ID
 *
 */

const getEventById = async (id) => {
  let response_data = null;
  let error = null;
  let loading = true;

  try {
    const response = await http.get(`${API_BACKEND}${apiEndpoint}/${id}`);
    response_data = response.data.event;
    console.log(response.data.event);
  } catch (err) {
    error = err.response.data;
    console.log(err);
  } finally {
    loading = false;
  }

  return { response_data, error, loading };
};

/*
 *
 *
 */
const getLastEvent = async () => {
  let lastEvent = [];
  try {
    const { data } = await http.get(`${API_BACKEND}${apiEndpoint}/newest`);

    lastEvent = data.event;
  } catch (err) {
    console.log(err);
  }

  return { ...lastEvent[0] };
};

/*
 *
 *
 */

const getEvents = async () => {
  let error = null;
  let response_data = null;
  try {
    const response = await http.get(`${API_BACKEND}${apiEndpoint}/events`);
    response_data = response.data;
  } catch (err) {
    error = err.response.data;
  }

  return { response_data, error };
};

/*  Create new event */
const createEvent = async (requestData) => {
  let response_data = null;
  let error = null;


  try {
    const response = await http.post(
      `${API_BACKEND}${apiEndpoint}/create-event-ticket`,
      requestData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
    response_data = response.data;
  } catch (e) {
    console.log(e);
    error = e.response.data;
  }
  
  return { response_data, error };
};

// Update Event And Ticket

const editEventAndTicket = async (requestData, id) => {
  let response_data;
  let error;

  try {
    const response = await http.post(
      `${API_BACKEND}${apiEndpoint}/editEventAndTicket/${id}`,
      requestData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
    response_data = response.data;
  } catch (e) {
    console.log(e.response.data);
    error = e.response.data;
  }

  return { response_data, error };
};
export {
  getEventById,
  getLastEvent,
  getEvents,
  createEvent,
  editEventAndTicket,
};
