import { t } from "i18next";
import { Link } from "react-router-dom";
import LoginForm from "../components/login/LoginForm";
import Stepper from "../components/UI/Stepper";

const Login = () => {
  return (
    <div className="authentification login">
      <div className="container">
        <h2>{t("how_to_get_ticket.info")}</h2>
        <Stepper />
        <div className="auth-form">
          <h1>{t("form.login")}</h1>
          <h4>
            {t("form.dont_have_account")}{" "}
            <Link to="/register">{t("form.sign_up")}</Link>
          </h4>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
