const Input = ({ type, error, id, name, placeholder, onChange, value }) => {
  return (
    <input
      type={type}
      className={
        error ? "is-invalid form-control-error form-control" : "form-control"
      }
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
};

export default Input;
