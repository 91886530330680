const convertDate = (date) => {
    const originalDate = new Date(date);

    // Get the date components
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(originalDate.getDate()).padStart(2, '0');
    const hours = String(originalDate.getHours()).padStart(2, '0');
    const minutes = String(originalDate.getMinutes()).padStart(2, '0');

    return {year, month, day, hours, minutes}
}

export {convertDate}