const Form = ({ onSubmit, children, errorForm, isLoading }) => {
  return (
    <form onSubmit={onSubmit}>
      {errorForm && (
        <div
          className="alert alert-danger d-flex align-items-center"
          role="alert"
        >
          <div>{errorForm}</div>
        </div>
      )}

      {children}
    </form>
  );
};

export default Form;
