import Joi from "joi";
import i18next from "i18next";
const schema = {
  first_name: Joi.string()
    .trim()
    .min(3)
    .max(50)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          case "string.min":
            err.message = i18next.t("validation.first_name_limit_min", {
              limit: err.local.limit,
            });
            break;
          case "string.max":
            err.message = i18next.t("validation.first_name_limit_min", {
              limit: err.local.limit,
            });
            break;

          default:
            break;
        }
      });
      return errors;
    }),

  // Last Name
  last_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          case "string.min":
            err.message = i18next.t("validation.last_name_limit_min", {
              limit: err.local.limit,
            });
            break;

          case "string.max":
            err.message = i18next.t("validation.last_name_limit_max", {
              limit: err.local.limit,
            });
            break;

          default:
            break;
        }
      });
      return errors;
    }),


  address: Joi.string()
    .trim()
    .min(3)
    .max(255)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          case "string.min":
            err.message = i18next.t("validation.address_min", {
              limit: err.local.limit,
            });
            break;
          case "string.max":
            err.message = i18next.t("validation.address_max", {
              limit: err.local.limit,
            });
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  gender: Joi.any()
    .valid("Male", "Female")
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          case "any.only":
            err.message = i18next.t("validation.gender");
            break;
          default:
            break;
        }
      });
      return errors;
    }),

  birthday: Joi.date()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "date.base":
          case "date.strict":
            err.message = i18next.t("validation.birthday");
            break;
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          default:
            break;
        }
      });
      return errors;
    }),
};

export { schema };
