import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const ProtectedPages = ({ allowedRoles }) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();
  console.log("User, ", user);
  return isLoggedIn ? (
    allowedRoles.includes(user.role) ? (
      <Outlet />
    ) : (
      <Navigate to="/" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedPages;
