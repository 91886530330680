import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Form from "../UI/form/Form";
import Field from "../UI/form/Field";
import { schema } from "../register/form/validation";
import { validateForm, validateProprety } from "../../utils/validation";
import http from "../../services/http";
import { API_BACKEND } from "../../utils/api";

const ResetPasswordForm = () => {
  const [form, setForm] = useState({
    password: "",
    repeatPassword: "",
  });
  const [errors, setErrors] = useState({
    password: false,
    repeatPassword: false,
    error: false,
  });
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const { t } = useTranslation();

  //Onchange Input Handler
  const onChangeHandler = (e) => {
    let newError;

    const newData = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(newData);

    const error = validateProprety(
      schema,
      e.target.name,
      e.target.value,
      newData
    );
    newError = { ...errors, ...error };
    console.log(errors);
    setErrors(newError);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Validate Data
    const validation = validateForm(
      _.pick(schema, ["password", "repeatPassword"]),
      form
    );
    const isValid = _.pick(validation, "isValidForm").isValidForm;
    const newErrors = _.omit(validation, "isValidForm");
    console.log();
    if (!isValid) {
      console.log("isvlaid!");
      setIsLoading(false);
      return setErrors(newErrors);
    }

    try {
      const { data } = await http.patch(
        `${API_BACKEND}/user/reset-password/${token}`,
        {
          password: form.password,
        }
      );
      setMessage(t(data.message));
      setError(null);
    } catch (err) {
      console.log(err);
      setMessage(null);
      setError(t(err.response.data.error.message));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form
        onSubmit={onSubmitHandler}
        errorForm={errors.error}
        isLoading={isLoading}
      >
        {message && <div className="alert alert-success">{message}</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        <Field
          label={t("form.password")}
          name="password"
          type="password"
          onChange={onChangeHandler}
          value={form.password}
          error={errors.password}
        />
        <Field
          label={t("form.repeat_password")}
          name="repeatPassword"
          type="password"
          onChange={onChangeHandler}
          value={form.repeatPassword}
          error={errors.repeatPassword}
        />
        {!isLoading && (
          <button className="btn btn-form">
            {t("call_to_action.confirm")}
          </button>
        )}
        {isLoading && <div className="btn btn-form">{t("loading")}</div>}
      </Form>
      <Link to="/" style={{ textAlign: "center", display: "block" }}>
        {t("back_home")}
      </Link>
    </>
  );
};

export default ResetPasswordForm;
