import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLanguages from "../../hooks/useLanguages";

import { getEvents } from "../../services/event";
import EventListLoader from "../UI/loader/EventListLoader";
import EventCard from "./EventCard";

const Events = () => {
  //  const { currentLangCode } = useLanguages();
  // States
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response_data, error } = await getEvents();

        setEvents(response_data.data.events);
      } catch (err) {
        //setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="events">
      <div className="container-fluid">
        <h2>{t("upcoming_events")}</h2>
        <div className="row">
          {loading ? (
            <div className="row">
              <div className="col-lg-4">
                <EventListLoader />
              </div>
              <div className="col-lg-4">
                <EventListLoader />
              </div>
              <div className="col-lg-4">
                <EventListLoader />
              </div>
            </div>
          ) : events.length === 0 ? (
            <div>{t("no_event_availaibale")}</div>
          ) : (
            events.map((item) => <EventCard key={item.id} event={item} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
