import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import http from "../services/http";

import TicketMockup from "../components/UI/ticket-mockup/TicketMockup";
import PaymentSuccess from "../components/checkout/PaymenSuccess";
import PaymentFailed from "./PaymentFailed";
import useHttpPrivate from "../hooks/useHttpPrivate";
import { API_BACKEND } from "../utils/api";
import useLanguages from "../hooks/useLanguages";
const Checkout = () => {
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [data, setData] = useState({ actionCodeDescription: null });
  const [searchParams] = useSearchParams();
  const { currentLangCode } = useLanguages();
  //
  const httpPrivate = useHttpPrivate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpPrivate.post(
          `${API_BACKEND}/booking/confirm-booking/`,
          {
            orderId: searchParams.get("orderId"),
            language: currentLangCode,
          }
        );

        setPaymentSuccess(true);
        console.log({ ...response.data });
        setData({ ...response.data });
      } catch (err) {
        console.log({ ...err.response.data });
        setData({ ...err.response.data });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  /*useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.post("/booking/checkout", {
          orderId: searchParams.get("orderId"),
        });

        if (data.type === "payment_success") {
          setPaymentSuccess(true);
          setData(data);
        }
      } catch (err) {
        setPaymentSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchParams]);*/

  const successPage = <PaymentSuccess data={data} />;

  const failedPage = (
    <PaymentFailed actionCodeDescription={data.actionCodeDescription} />
  );
  return loading ? (
    <Loading text="a" />
  ) : paymentSuccess ? (
    successPage
  ) : (
    failedPage
  );
};

export default Checkout;
