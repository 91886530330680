import dz from "../images/flags/dz.png";
import uk from "../images/flags/uk.png";
import fr from "../images/flags/fr.png";
const languages = [
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "dz",
    img: dz,
  },
  {
    code: "en",
    name: "English",
    dir: "ltr",
    country_code: "uk",
    img: uk,
  },
  {
    code: "fr",
    name: "Français",
    dir: "ltr",
    country_code: "fr",
    img: fr,
  },
];

export default languages;
