import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
const ColumnOne = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  console.log(user);
  return (
    <div className="nav-column-1">
      <div className="logo">PALAIS-TLM</div>
      <div className="links">
        <ul>
          <li>
            <Link className="normal-link" to="/">
              {t("menu.home")}
            </Link>
          </li>
          {user?.role === "admin" && (
            <>
              <li>
                <Link to="/dashboard/create-event" className="normal-link">
                  {t("menu.add_event")}
                </Link>
              </li>
              <li>
                <Link to="/dashboard/verifyTicket" className="normal-link">
                  {t("menu.verify_ticket")}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ColumnOne;
