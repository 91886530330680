const TicketMockup = () => {
  return (
    <div className="ticket-mockup">
      <div className="ticket-box">
        <div className="left">
          <div className="image">
            <div className="ticket-number">
              <p>#20030220</p>
            </div>
          </div>
          <div className="ticket-info">
            <p className="date">
              <span>TUESDAY</span>
              <span className="june-29">JUNE 29TH</span>
              <span>2021</span>
            </p>
            <div className="show-name">
              <h1>Championnat d'algerie divison 1m</h1>
              <h2>Bourdj basdek bourerij VS Widad Tlemcen</h2>
            </div>
            <div className="time">
              <p>HEURE: 18:00</p>
            </div>
            <p className="location">
              <span>STADE AKID LOTFI</span>
              <span className="separator">
                <i className="far fa-smile"></i>
              </span>
              <span>TRIBUNE GRADIN 2 PLACE 450</span>
            </p>
          </div>
        </div>
        <div className="right">
          <div className="right-info-container">
            <div className="show-name">
              <h1>BENFLANE MOHAMMED AHMED</h1>
            </div>

            <div className="barcode">
              <img
                src="https://external-preview.redd.it/cg8k976AV52mDvDb5jDVJABPrSZ3tpi1aXhPjgcDTbw.png?auto=webp&s=1c205ba303c1fa0370b813ea83b9e1bddb7215eb"
                alt="QR code"
              />
            </div>
            <p className="ticket-number">#20030220</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketMockup;
