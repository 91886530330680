import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";
import Cookies from "js-cookie";
import languages from "../../../i18n/languages";

const initialState = {
  currentLangCode: Cookies.get("i18next"),
  currentLang: languages.find((lang) => lang.code === Cookies.get("i18next")),
};
//document.body.dir = initialState.currentLang.dir || "ltr";
console.log(initialState, Cookies.get("i18next"));
const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      console.log(action.payload);
      state.currentLangCode = action.payload;
      state.currentLang = languages.find(
        (lang) => lang.code === action.payload
      );
      document.body.dir = state.currentLang.dir || "ltr";
      i18next.changeLanguage(action.payload);
    },
  },
});

export const langAction = langSlice.actions;
export default langSlice;
