import { useTranslation } from "react-i18next";

import "moment/min/locales.min";

import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
// Hooks
import useLanguages from "../hooks/useLanguages";

const Condition = () => {
  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  return (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          <h1>Conditions d'utilisation</h1>
          <p>
            Bienvenue sur notre site de vente de billets pour le Palais de la
            Culture de Tlemcen. Veuillez lire attentivement les conditions
            générales d'utilisation avant d'utiliser le site.
          </p>

          <h2>1. Acceptation des conditions</h2>
          <p>
            En utilisant ce site, vous acceptez de vous conformer aux conditions
            d'utilisation énoncées ici. Si vous n'acceptez pas ces conditions,
            veuillez ne pas utiliser le site.
          </p>

          <h2>2. Billets et transactions</h2>
          <p>
            Les billets sont vendus sur le site pour réserver l'entrée au Palais
            de la Culture de Tlemcen. Les utilisateurs doivent respecter les
            politiques de paiement et d'annulation spécifiées pour chaque
            événement.
          </p>

          <h2>3. Responsabilité</h2>
          <p>
            Nous ne sommes pas responsables de toute perte ou dommage résultant
            de l'utilisation de ce site ou de vos transactions avec le Palais de
            la Culture de Tlemcen.
          </p>

          <h2>4. Propriété intellectuelle</h2>
          <p>
            Tous les droits de propriété intellectuelle relatifs au site et à
            son contenu nous appartiennent et il est interdit de copier ou de
            distribuer le contenu sans autorisation préalable.
          </p>

          <h2>5. Informations personnelles</h2>
          <p>
            Nous respectons la vie privée des utilisateurs et nous nous
            engageons à protéger leurs informations personnelles conformément à
            la politique de confidentialité énoncée sur le site.
          </p>

          <h2>6. Modifications et mises à jour</h2>
          <p>
            Nous nous réservons le droit de modifier les conditions
            d'utilisation à tout moment, et les utilisateurs doivent consulter
            régulièrement ces conditions.
          </p>

          <p>
            En utilisant ce site, vous acceptez ces conditions générales. Merci
            de votre visite.
          </p>
        </div>
      </div>
    </>
  );
};

export default Condition;
