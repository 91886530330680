const HamburgerIcon = ({ toggleHamburger, onToggleHamburger }) => {
  return (
    <div
      class={toggleHamburger ? "nav-hamburger toggle" : "nav-hamburger"}
      onClick={onToggleHamburger}
    >
      <div class="line1"></div>
      <div class="line2"></div>
      <div class="line3"></div>
    </div>
  );
};

export default HamburgerIcon;
