import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ColumnThree = () => {
  const { t } = useTranslation();
  return (
    <div className="nav-column-3">
      <div className="links">
        <ul>
          <li>
            <Link className="styled-link link-style-2" to="/login">
              {t("menu.login")}
            </Link>
          </li>
          <li>
            <Link className="styled-link link-style-1" to="/register">
              {t("menu.register")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ColumnThree;
