import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "../UI/form/Form";
import Field from "../UI/form/Field";
import http from "../../services/http";
import { API_BACKEND } from "../../utils/api";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await http.post(`${API_BACKEND}/user/forgot-password`, {
        email,
      });
      setMessage(t(data.message));
      setError(null);
    } catch (err) {
      setError(t(err.response.data.error.message));
      setMessage(null);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form onSubmit={onSubmitHandler} errorForm={error} isLoading={isLoading}>
        {message && <div className="alert alert-success">{message}</div>}

        <Field
          label={t("form.email")}
          name="email"
          type="text"
          placeholder="Ex: yourmail@mail.com"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {!isLoading && (
          <button className="btn btn-form">
            {t("call_to_action.confirm")}
          </button>
        )}
        {isLoading && <div className="btn btn-form">{t("loading")}</div>}
      </Form>
      <Link to="/" style={{ textAlign: "center", display: "block" }}>
        {t("back_home")}
      </Link>
    </>
  );
};

export default ForgotPasswordForm;
