import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegisterForm from "../components/register/form/RegisterForm";
import Stepper from "../components/UI/Stepper";

const Register = () => {
  const { t } = useTranslation();
  return (
    <div className="register authentification">
      <div className="container">
        <h2 style={{ textTransform: "uppercase" }}>
          {t("how_to_get_ticket.info")}
        </h2>

        <Stepper />
        <div className="auth-form">
          <h1>{t("form.create_account_title")}</h1>
          <h4>
            {t("form.have_account")} <Link to="/login">{t("form.login")}</Link>
          </h4>
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default Register;
