import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import useHttpPrivate from "../../hooks/useHttpPrivate";

import Loading from "../../components/Loading";

import Form from "../../components/UI/form/Form";
import NavbarResponsive from "../../components/UI/navbar/NavbarResponsive";

import { ToastContainer, toast } from "react-toastify";
import { API_BACKEND } from "../../utils/api";
import { useTranslation } from "react-i18next";

const EditEventAndTicket = () => {
  const [name_en, setNameEn] = useState("");
  const [name_fr, setNameFr] = useState("");
  const [name_ar, setNameAr] = useState("");
  const [description_en, setDescriptionEn] = useState("");
  const [description_fr, setDescriptionFr] = useState("");
  const [description_ar, setDescriptionAr] = useState("");

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("")

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [image, setImage] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [eventStatus, setEventStatus] = useState(true);

  const imageFileRef = useRef("imageFileRef");

  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);

  const httpPrivate = useHttpPrivate();

  const { t } = useTranslation();

  //
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.get(`${API_BACKEND}/event/${id}`);

        setNameEn(data.event.name_en);
        setNameAr(data.event.name_ar);
        setNameFr(data.event.name_fr);
        setDescriptionEn(data.event.description_en);
        setDescriptionFr(data.event.description_fr);
        setDescriptionAr(data.event.description_ar);
        setStartDate(data.event.start_date);
        setEndDate(data.event.end_date);
  
        setImage(data.event.image);
        setQuantity(data.event.Tickets[0].quantity);
        setPrice(data.event.Tickets[0].price);
        setEventStatus(() => (data.event.actif ? true : false));
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const formData = new FormData();

    formData.append("name_ar", name_ar);
    formData.append("name_fr", name_fr);
    formData.append("name_en", name_en);
    formData.append("description_en", description_en);
    formData.append("description_fr", description_fr);
    formData.append("description_ar", description_ar);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("image", image);
    formData.append("changeImage", changeImage);
    formData.append("quantity", quantity);
    formData.append("price", price);
    formData.append("actif", eventStatus);

    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/event/editEventAndTicket/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(t(data.message), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // Reset States

      setError("");
    } catch (err) {
      if (err.response.data.message) {
        toast.error(t(err.response.data.message), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setError(t(err.response.data.message));
      } else {
        toast.error("Something unkonw happen", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
      setSubmitLoading(false);
    }
  };
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <NavbarResponsive />
      <div className="booking">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h1 className="title">{t("add_event.edit_event_title")}</h1>
              <h4 className="sub-title">Please fill all form proprely</h4>
              <div>
                <Form onSubmit={handleSubmit} errorForm={error.message}>
                  <div className="spectator-field">
                    <h2>{t("add_event.info_event")}</h2>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_en")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_en}
                          onChange={(e) => setNameEn(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_fr")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_fr}
                          onChange={(e) => setNameFr(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_ar")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_ar}
                          onChange={(e) => setNameAr(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_en")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_en}
                          onChange={(e) => setDescriptionEn(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_fr")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_fr}
                          onChange={(e) => setDescriptionFr(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_ar")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_ar}
                          onChange={(e) => setDescriptionAr(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label">
                            تاريخ بداية الحدث
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={start_date}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            تاريخ نهاية الحدث
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={end_date}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label forHtml="formFile" class="form-label">
                          {t("add_event.upload_image")}
                        </label>
                        <div className="col">
                          <p>{t("add_event.change_image")}</p>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="option1"
                              onClick={() => setChangeImage(true)}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              {t("add_event.yes")}
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="option2"
                              onClick={() => setChangeImage(false)}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              {t("add_event.no")}
                            </label>
                          </div>
                        </div>

                        {changeImage && (
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            onChange={(e) => setImage(e.target.files[0])}
                            ref={imageFileRef}
                            required
                          />
                        )}
                      </div>
                      <h2>{t("add_event.info_ticket")}</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label">
                            {t("add_event.nbr_ticket")}:{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ex: 100"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            {t("add_event.price")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ex: 100"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <h2>{t("add_event.status_event")}</h2>
                      <div className="row">
                        <label forHtml="formFile" class="form-label">
                          {t("add_event.disable_active")}
                        </label>
                        <div className="col">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions2"
                              id="inlineRadio3"
                              value="0"
                              onClick={() => setEventStatus(false)}
                              checked={!eventStatus}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              {t("add_event.disable")}
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions2"
                              id="inlineRadio4"
                              value="1"
                              onClick={() => setEventStatus(true)}
                              checked={eventStatus}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              {t("add_event.active")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {submitLoading && (
                    <div className="btn btn-primary">{t("loading")}</div>
                  )}
                  {!submitLoading && (
                    <button className="btn btn-primary">
                      {t("add_event.cta_edit_event")}
                    </button>
                  )}
                  <Link to="/" className="btn btn-danger">
                    {t("call_to_action.cancel")}
                  </Link>
                </Form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEventAndTicket;
