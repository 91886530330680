import { useState } from "react";
import ColumnOne from "./ColumnOne";
import ColumnThree from "./ColumnThree";
import ColumnTwo from "./ColumnTwo";
import HamburgerIcon from "./HamburgerIcon";

import ColumnLoggedIn from "./ColumnLoggedIn";
import useAuth from "../../../hooks/useAuth";
import usePersistLogin from "../../../hooks/usePersistLogin";
import ToolbarConfirmEmail from "../toolbar/ToolbarConfirmEmail";

const NavbarResponisve = () => {
  // Persist Login

  const { user } = useAuth();
  const { isLoggedIn: persistLogin } = usePersistLogin();

  //const { isLoggedIn: persist } = usePersistLogin();

  //console.log(isLoggedIn, "persist", persistLogin);
  // States
  const [toggleHamburger, setToggleHamburger] = useState(false);

  return (
    <div className="">
      <nav className="navigation-responsive">
        {/*persistLogin && !user?.emailConfirmed && <ToolbarConfirmEmail />*/}
        <div className="container">
          <div className={toggleHamburger ? "wrapper show" : "wrapper"}>
            <div
              className={
                toggleHamburger
                  ? "navigation-responsive-col-fx light"
                  : "navigation-responsive-col-fx"
              }
            >
              <HamburgerIcon
                toggleHamburger={toggleHamburger}
                onToggleHamburger={() => setToggleHamburger((prev) => !prev)}
              />
            </div>
            <ColumnOne />
            <ColumnTwo />
            {persistLogin ? <ColumnLoggedIn /> : <ColumnThree />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarResponisve;
