import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Input from "../UI/form/Input";

const SpectatorFields = ({
  id,
  index,
  onChangeHandler,
  onDelete,
  data,
  errors,
}) => {
  // Translation
  const { t } = useTranslation();

  //Ref
  const spectatorRef = useRef();

  //

  // error
  const error = errors.find((errorSpectator) => errorSpectator.id === id);

  useEffect(() => {
    let mounted = true;
    if (mounted && !error.isValidForm) {
      spectatorRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  });

  return (
    <div className="spectator-field" id={id} ref={spectatorRef}>
      <div className="spectator-count">
        <h4>
          {t("spectator")} #{index + 1}
        </h4>
        {index !== 0 ? (
          <button
            type="button"
            className="btn btn-danger"
            onClick={onDelete.bind(null, id)}
          >
            {t("delete_spectator")}
          </button>
        ) : null}
      </div>

      <div className="row">
        <div className="col-md-6">
          <label forhtml={`first_name#${id}`} className="form-label">
            {t("form.first_name")}:
          </label>

          <Input
            type="text"
            name={`first_name#${id}`}
            value={data.first_name}
            onChange={onChangeHandler}
            placeholder={`${t("form.placeholders.example")} Benflane`}
          />
          {error.first_name && (
            <div className="invalid-feedback">{error.first_name}</div>
          )}
        </div>
        <div className="col-md-6">
          <label forhtml={`last_name#${id}`} className="form-label">
            {t("form.last_name")}:
          </label>
          <Input
            type="text"
            name={`last_name#${id}`}
            value={data.last_name}
            onChange={onChangeHandler}
            placeholder={`${t("form.placeholders.example")} Aladdin`}
          />

          {error.last_name && (
            <div className="invalid-feedback">{error.last_name}</div>
          )}
        </div>
      </div>
    
      <div className="col-12">
        <label forhtml={`address#${id}`} className="form-label">
          {t("form.address")}
        </label>
        <Input
          type="text"
          name={`address#${id}`}
          value={data.address}
          onChange={onChangeHandler}
          placeholder={`${t("form.placeholders.example")} City Rose 01 Tlemcen`}
        />
        {error.address && (
          <div className="invalid-feedback">{error.address}</div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <fieldset className="row mb-3">
            <label className="form-label">{t("form.gender")}: </label>
            <div className="">
              <input
                class="form-check-input"
                type="radio"
                name={`gender#${id}`}
                id="male"
                value="Male"
                onChange={onChangeHandler}
                checked={data.gender === "Male"}
              />
              <label class="form-check-label" forhtml="male">
                {t("form.male")}
              </label>

              <input
                class="form-check-input"
                style={{ marginLeft: "10px" }}
                type="radio"
                name={`gender#${id}`}
                id="female"
                value="Female"
                onChange={onChangeHandler}
                checked={data.gender === "Female"}
              />
              <label class="form-check-label" forhtml="female">
                {t("form.female")}
              </label>
              {error.gender && (
                <div className="invalid-feedback">{error.gender}</div>
              )}
            </div>
          </fieldset>
        </div>

        <div className="col-md-6">
          <label forhtml={`birthday#${id}`} className="form-label">
            {t("form.birthday")}:
          </label>
          <input
            type="date"
            className="form-control"
            id="birthday"
            name={`birthday#${id}`}
            value={data.birthday}
            onChange={onChangeHandler}
          />
          {error.birthday && (
            <div className="invalid-feedback">{error.birthday}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpectatorFields;
