import useAuth from "./useAuth";
import http from "../services/http";

const useRefreshToken = () => {
  const { login } = useAuth();

  const refresh = async () => {
    const { data } = await http.get("/refreshToken", {
      withCredentials: true,
    });
    login(data);

    return data;

    //console.log(data);
    //login(data.accessToken);
  };
  return refresh;
};

export default useRefreshToken;
