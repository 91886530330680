import { useEffect } from "react";
import { Link } from "react-router-dom";
import ConfettiGenerator from "confetti-js";
import { t } from "i18next";
import { API_BACKEND } from "../../utils/api";

const PaymentSuccess = ({ data }) => {
  useEffect(() => {
    const confettiSettings = {
      target: "confetti-holder",
      max: 103,
      size: 1,
      animate: true,
      props: ["circle", "square", "triangle", "line"],
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],
      ],
      clock: "35",
      rotate: true,
      start_from_edge: false,
      respawn: true,
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []); // add the var dependencies or not
  return (
    <div className="thankyou">
      <canvas id="confetti-holder"></canvas>
      <div className="container">
        <div className="thankyou-box">
          <i className="fa-solid fa-circle-check checkmark"></i>
          <h1>{data.satim_ipay.actionCodeDescription}</h1>
          <span className="orderNumber">
            {t("number_transaction")}: <b>#{data.satim_ipay.OrderNumber}</b>
          </span>
          <h3 className="email-sent">{t("message_confirmation_payment")}</h3>

          <div className="lines-1"></div>
          <div className="lines-2"></div>
          <div className="lines-3"></div>
          <div className="lines-4"></div>
          <div className="buttons">
            <Link to="/my-tickets" className="btn btn-ticket">
              <i className="fa-solid fa-ticket"></i> {t("menu.my_tickets")}
            </Link>
            <a
              href={`${API_BACKEND}/invoices/${data?.invoicePath
                ?.split("\\")
                .pop()
                .split("/")
                .pop()}`}
              className="btn btn-receipt"
            >
              <i className="fa-solid fa-file-invoice"></i>{" "}
              {t("download_your_receipt")}{" "}
            </a>
          </div>
          <div className="new-order">
            <Link to="/" className="link-new-order">
              {t("back_home")} &gt;&gt;
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
