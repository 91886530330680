const Loading = ({ text }) => {
  return (
    <>
      <div className="loading">
        <div></div>
        {text && (
          <p
            style={{
              marginTop: "20px",
              fontWeight: 500,
              fontSize: "18px",
              color: "#e91e63",
            }}
          >
            جاري التحقق. لاتغادر الصفحة
          </p>
        )}
      </div>
    </>
  );
};

export default Loading;
