import { authAction } from "../store/features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

const useAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);

  const login = (token) => {
    dispatch(authAction.login(token));
  };
  const logout = () => {
    dispatch(authAction.logout());
  };
  return { dispatch, authAction, login, logout, isLoggedIn, accessToken, user }; // dispatch(authAction.)
};

export default useAuth;
