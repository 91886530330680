import http from "./http";

const apiEndpoint = `/user`;

const register = async (data, recaptchaToken) => {
  let error;
  let response_data;
  try {
    const response = await http.post(`${apiEndpoint}/register`, {
      ...data,
      recaptchaToken,
    });

    response_data = response.data;
  } catch (err) {
    error = err.response.data.error;
  }

  return { error, response_data };
};

export { register };
