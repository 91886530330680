import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import bookingSlice from "./features/booking/bookingSlice";
import langSlice from "./features/lang/langSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    booking: bookingSlice.reducer,
    lang: langSlice.reducer,
  },
});

export default store;
