import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { bookingAction } from "../../store/features/booking/bookingSlice";
import Form from "../UI/form/Form";
import { schema } from "./validation";
import { valdiateMultipleForm, validateProprety } from "../../utils/validation";
import SpectatorFields from "./SpectatorFields";
import useLanguages from "../../hooks/useLanguages";
import Cookies from "js-cookie";
import { useEffect } from "react";

const SetInformation = () => {
  // Booking Store
  const dispatch = useDispatch();
  const {
    checkInformation,
    totalPrice,
    ticket,
    spectators,
    spectators_errors,
  } = useSelector((state) => state.booking);

  // i18next-react Translation
  const { t } = useTranslation();

  // States
  const [data, setData] = useState(spectators);

  const [errors, setErrors] = useState(spectators_errors);
  const [error, setError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // Functions

  // Add Spectator
  const addSpectator = () => {
    if (data.length === 5) return;

    //Update Total Price
    dispatch(bookingAction.setTotalPrice(parseInt(totalPrice + ticket.price)));
    console.log(totalPrice, ticket);
    // Initial State variables for Data and Errors
    const initialSpectatorBooking = {
      first_name: "",
      last_name: "",
      gender: "Male",
      birthday: "",
      address: "",
    };
    const initilaSpectatorBookingErrors = {
      first_name: false,
      last_name: false,
      gender: false,
      birthday: false,
      address: false,
    };

    const newID = uuidv4();
    // New Data fields
    const newSpectator = {
      id: newID,
      ...initialSpectatorBooking,
    };
    setData([...data, newSpectator]);

    // New Errors Data Fields
    const newErrorSpectator = {
      id: newID,
      ...initilaSpectatorBookingErrors,
    };
    setErrors([...errors, newErrorSpectator]);
  };

  // Delete Spectator
  const delete_spectator = (id) => {
    const newSpectators = data.filter((spectator) => spectator.id !== id);
    const newSpectatorsErrors = errors.filter(
      (spectatorErr) => spectatorErr.id !== id
    );

    setData(newSpectators);
    setErrors(newSpectatorsErrors);

    // Reset Total Price
    dispatch(bookingAction.setTotalPrice(parseInt(totalPrice - ticket.price)));
    //setTotalPrice();
  };

  // On Change Handler States
  const onChangeHandler = (e, index, value) => {
    // Get Current Spectator ID and Target Name Input
    const spectator_id = e.target.name.split("#").slice(-1).join();
    const targetName = e.target.name.split("#").slice(0, -1).join("");

    console.log(spectator_id, targetName);
    // Get Option Element selected
    const elem = document.getElementById(e.target.name);
    console.log(elem);
    // Set New Data

    /// Validate Data
    const validation = validateProprety(schema, targetName, e.target.value);

    const newError = errors.map((error) => {
      if (error.id === spectator_id) {
        return {
          id: spectator_id,

          ...error.spectator_booking,
          ...validation,
        };
      } else {
        return {
          ...error,
        };
      }
    });

    const newData = data.map((item) => {
      if (item.id === spectator_id) {
        return {
          id: item.id,
          ...item,
          [targetName]: e.target.value,
        };
      } else {
        return { ...item };
      }
    });
    setData([...newData]);
    setErrors([...newError]);
    // Set Total Price
    //setTotalPrice(newData);
  };

  // Form Submit Handler
  const onSubmitHandler = (e) => {
    e.preventDefault();

    //Validata Data

    const validation = valdiateMultipleForm(schema, data);
    const isValid = _.pick(validation, "isValidForm").isValidForm;
    const { errors: errorsValidation } = _.omit(validation, "isValidForm");
    console.log(errorsValidation);
    toast.error("الرجاء ملئ جميع الحقول بشكل مناسب", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    //setErrors([...errorsValidation]);

    // Check if is Valid Form
    if (isValid) {
      dispatch(bookingAction.setInformation({ data, errors }));
    }

    /*if (isValid) {
      dispatch(bookingAction.toogleCheckInformation());
      dispatch(bookingAction.setInformation({ ...data }));
    } /*
    //Send data to redux

    //setToCheckInformation(true)
    //[data, toCheckInformation, token.id_user, response.ticket_id]*/
  };
  return (
    <>
      <Form onSubmit={onSubmitHandler} errorForm={error} isLoading={isLoading}>
        {data.map((spectator, index) => {
          return (
            <SpectatorFields
              key={spectator.id}
              id={spectator.id}
              index={index}
              onChangeHandler={onChangeHandler}
              onDelete={delete_spectator}
              data={data.find((s) => s.id === spectator.id)}
              errors={errors}
            />
          );
        })}

        <button type="button" className="btn btn-info" onClick={addSpectator}>
          {t("add_new_spectator")}
        </button>
        {isLoading && <div className="btn btn-primary">{t("loading")}</div>}

        {!isLoading && (
          <button type="submit" className="btn btn-primary">
            {t("call_to_action.confirm")}
          </button>
        )}
      </Form>
      <ToastContainer />
    </>
  );
};

export default SetInformation;
