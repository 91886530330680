import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/min/locales.min";
import useLanguages from "../../hooks/useLanguages";
import { API_BACKEND } from "../../utils/api";

const TicketItem = ({ event }) => {
  //Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();
  const [langLocale, setLangLocale] = useState(currentLangCode);
  const iconPlusRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  //change moment local
  useEffect(() => {
    setLangLocale(currentLangCode === "ar" ? "ar_DZ" : currentLangCode);

    moment.locale(currentLangCode === "ar" ? "ar_DZ" : currentLangCode);
  }, [currentLangCode]);
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="ticket-item">
      <div
        className="ticket-header"
        id={`heading${event.id}`}
        data-bs-toggle="collapse"
        data-bs-target={`#collapse${event.id}`}
        aria-expanded="false"
        aria-controls={`collapse${event.id}`}
      >
        <div className="ticket-event">
          <div className="event-date">
            {moment(event.start_date).format("dddd Do MMMM YYYY")}
          </div>
          <div className="event-title">{event[`name_${currentLangCode}`]}</div>
          <div className="icon-plus" ref={iconPlusRef}>
            +
          </div>
        </div>
      </div>

      <div
        id={`collapse${event.id}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${event.id}`}
        data-bs-parent="#accordionTickets"
      >
        <div className="ticket-body table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>order ID</th>
                <th>Date d'achat</th>
                <th>nombre de personne</th>
                <th>{t("ticket")}</th>
                <th>receipt</th>
                
              </tr>
            </thead>
            <tbody>
              {event.Purchases.map((purchase) =>
                <tr>
                  <td><b>{purchase.orderId}</b></td>
                  <td>{purchase.createdAt}</td>
                  <td>{purchase.quantity}</td>
                  <td><a href={`${API_BACKEND}/tickets/${purchase.orderNumber}/${purchase.orderId}.pdf`}>{t("download_ticket")}</a></td>
                  <td><a href={`${API_BACKEND}/invoices/${purchase.orderId}.pdf`}>{t("download_receipt")}</a></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TicketItem;
