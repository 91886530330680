import { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import Loading from "../../components/Loading";
import Stepper from "../../components/UI/Stepper";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import { API_BACKEND } from "../../utils/api";
const ConfirmEmail = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("null");
  const [message, setMessage] = useState(null);

  const httpPrivate = useHttpPrivate();
  useEffect(() => {
    const confirmEmailRequest = async () => {
      setIsLoading(true);
      try {
        const { data } = await httpPrivate.post(
          `${API_BACKEND}/user/confirm-email/${token}`
        );
        setMessage(data.message);
      } catch (err) {
        setError(err.response.error.message);
      } finally {
        setIsLoading(false);
      }
    };
    confirmEmailRequest();
  }, [token]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="authentification login">
      <div className="container">
        <h2>{t("how_to_get_ticket.info")}</h2>
        <Stepper />
        <div className="auth-form">
          {message && <div className="alert alert-success">{t(message)}</div>}
          {error && <div className="alert alert-danger">{t(error)}</div>}
        </div>
        <Link
          style={{ textAlign: "center", display: "block", color: "#fff" }}
          to="/"
        >
          {t("back_home")}
        </Link>{" "}
      </div>
    </div>
  );
};

export default ConfirmEmail;
