import axios from "axios";
import { API_BACKEND } from "../utils/api";

/*axios.interceptors.response.use(
  (null,
  (error) => {
    console.log("INTERCEPTORS");
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      console.log("Logging the error", expectedError);
    }

    return Promise.reject(error);
  })
);*/

export default axios.create({
  baseURL: API_BACKEND,
});

export const httpPrivate = axios.create({
  baseURL: API_BACKEND,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
