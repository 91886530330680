import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const id = uuidv4();
const initialState = {
  checkInformation: false,
  spectators: [
    {
      id,
      first_name: "",
      last_name: "",
      address: "",
      gender: "Male",
      birthday: "",
    },
  ],
  spectators_errors: [
    {
      id,
      first_name: false,
      last_name: false,
      address: false,
      gender: false,
      birthday: false,
    },
  ],

  ticket: {
    id: null,
    price: null,
  },
  totalPrice: 0,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setInformation: (state, { payload }) => {
      state.spectators = payload.data;
      state.spectators_errors = payload.errors;
      state.checkInformation = true;
    },
    setTicket: (state, { payload }) => {
      state.ticket.id = payload.id;
      state.ticket.price = payload.price;
    },
    setTotalPrice(state, { payload }) {
      state.totalPrice = payload;
    },

    toogleCheckInformation: (state, payload) => {
      state.checkInformation = !state.checkInformation;
    },

    resetBooking: (state, payload) => {
      state.checkInformation = false;
      state.spectators = [
        {
          id,
          first_name: "",
          last_name: "",
          address: "",
          gender: "Male",
          birthday: "",
        },
      ];
      state.spectators_errors = [
        {
          id,
          first_name: false,
          last_name: false,
          address: false,
          gender: false,
          birthday: false,
        },
      ];
      state.ticket = {
        id: null,
        price: null,
      };
      state.totalPrice = 0;
    },
  },
});

export const bookingAction = bookingSlice.actions;
export default bookingSlice;
