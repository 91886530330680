import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
const Stepper = () => {
  const { t } = useTranslation();
  const currentLang = cookies.get("i18next");
  return (
    <div className="stepper">
      <div className="step1 steps">
        <div className="circle">
          <i class="fa-solid fa-user-pen"></i>
        </div>
        <div
          className={
            currentLang === "fr" ? "text-step text-step-fr" : "text-step"
          }
        >
          {t("how_to_get_ticket.create_account")}
        </div>
      </div>
      <div className="step2 steps">
        <div className="circle">
          <i class="fa-solid fa-arrow-pointer"></i>
        </div>
        <div
          className={
            currentLang === "fr" ? "text-step text-step-fr" : "text-step"
          }
        >
          {t("how_to_get_ticket.book_place")}
        </div>
      </div>
      <div className="step3 steps">
        <div className="circle">
          <i class="fa-solid fa-credit-card"></i>
        </div>
        <div
          className={
            currentLang === "fr" ? "text-step text-step-fr" : "text-step"
          }
        >
          {t("how_to_get_ticket.buy")}
        </div>
      </div>
      <div className="step4 steps">
        <div className="circle">
          <i class="fa-solid fa-ticket"></i>
        </div>
        <div
          className={
            currentLang === "fr" ? "text-step text-step-fr" : "text-step"
          }
        >
          {t("how_to_get_ticket.get_ticket")}
        </div>
      </div>
    </div>
  );
};

export default Stepper;
