import { useState } from "react";
import Footer from "../components/UI/Footer";
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
import useHttpPrivate from "../hooks/useHttpPrivate";
import { API_BACKEND } from "../utils/api";

import { ToastContainer, toast } from "react-toastify";
import { QrReader } from "react-qr-reader";

import { useTranslation } from "react-i18next";

const VerifyTicket = () => {
  const [booking_id, setBookingId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);
  const [error, setError] = useState(null);

  const httpPrivate = useHttpPrivate();

  const { t } = useTranslation();

  const handleVerifyClick = async () => {
    setLoading(true);
    setError(null);
    setTicketInfo(null);
    try {
      const { data } = await httpPrivate.post(`${API_BACKEND}/verifyTicket`, {
        booking_id,
      });
      setTicketInfo(data.spectator);
    } catch (err) {
      if (err?.response?.data?.error?.code === "TICKET_ALREADY_VALIDATE") {
        //setError(err?.response?.data?.error.message);
        setTicketInfo(err.response.data.error.spectator);
        return;
      }
      if (err?.response?.data?.error?.message) {
        setError(t(err.response.data.error.message));
      } else {
        toast.error("Something unknow happen refresh the page", {
          position: "bottom-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    setValidateLoading(true);
    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/verifyTicket/validate`,
        { booking_id }
      );

      toast.success(data.message, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      toast.error("Something unknow happen refresh the page", {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setValidateLoading(false);
      setError(null);
      setTicketInfo(null);
    }
  };

  return (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          <div className="verify-ticket">
            <div className="spectator-field">
              <div className="row">
                <div className="col-md-12">
                  <form className="verify-ticket-form">
                    <input
                      type="text"
                      value={booking_id}
                      onChange={(e) => setBookingId(e.target.value)}
                      placeholder="Enter ticket code"
                    />
                    <div>
                      <QrReader
                        facingMode={"environment"}
                        scanDelay={500}
                        onResult={(result, error) => {
                          if (!!result) {
                            setBookingId(result?.text);
                          }

                          if (!!error) {
                            console.info(error);
                          }
                        }}
                        containerStyle={{ width: "280px" }}
                        videoContainerStyle={{ width: "280px" }}
                        style={{ width: "300px" }}
                      />
                    </div>

                    <button type="button" onClick={handleVerifyClick}>
                      {t("cta_verify_ticket")}
                    </button>
                  </form>
                  {isLoading && (
                    <div className="verify-ticket-loading">
                      {t("verifying_ticket")}
                    </div>
                  )}
                  {error && (
                    <div className="verify-ticket-verification-status">
                      {error}
                    </div>
                  )}
                  {ticketInfo && (
                    <div className="verify-ticket-info">
                      {!!ticketInfo["validationStatus"] && (
                        <div className="verify-ticket-verification-status">
                          This ticket passed befote at time:{" "}
                          {ticketInfo["validationDate"]}
                        </div>
                      )}
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("form.first_name")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["first_name"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("form.last_name")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["last_name"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("form.nin")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["nin"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("form.birthday")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["birthday"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("form.gender")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["gender"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("event_title")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["Event.name_en"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("date")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["Event.date"]}
                        </div>
                      </div>
                      <div className="verify-ticket-info-row">
                        <div className="verify-ticket-info-label">
                          {t("time")}:
                        </div>
                        <div className="verify-ticket-info-value">
                          {ticketInfo["Event.hour"]}
                        </div>
                      </div>
                      {!!!ticketInfo["validationStatus"] && (
                        <>
                          {validateLoading ? (
                            <button
                              className="verify-ticket-validate"
                              type="button"
                            >
                              {t("loading")}
                            </button>
                          ) : (
                            <button
                              className="verify-ticket-validate"
                              type="submit"
                              onClick={handleValidate}
                              style={{ color: "#673ab7" }}
                            >
                              {t("validate_ticket")}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VerifyTicket;
