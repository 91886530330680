import http, { httpPrivate } from "./http";

const apiEndpoint = `/auth`;

const login = async (data) => {
  let user = null;
  let error = null;

  try {
    const response = await http.post(
      `${apiEndpoint}/login`,
      { ...data },
      { withCredentials: true }
    );
    user = response.data;
    console.log(user);
  } catch (err) {
    error = err.response.data.error;
    console.log(err);
  }
  /*try {
    const { data: getUser } = await http.post(
      `${apiEndpoint}/login`,
      { ...data },
      { withCredentials: true }
    );
    console.log(user);
    user = { token: getUser.token };
  } catch (err) {
    error = err.response.data.error;
  }*/

  return { user, error };
};
const logout = async () => {
  console.log("logout");
  try {
    await httpPrivate.post(`${apiEndpoint}/logout`);
    window.location = "/";
  } catch (err) {
    console.log(err);
  }
};

export { login, logout };
