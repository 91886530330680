import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { API_BACKEND } from "../utils/api";
import useHttpPrivate from "../hooks/useHttpPrivate";
import Loading from "../components/Loading";

const Confirmation = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const orderId = query.get("orderId");
  const httpPrivate = useHttpPrivate();
  useEffect(() => {
    console.log("Use eeffect");
    const fetchData = async () => {
      console.log("fetch data useeffect");
      try {
        const { data } = await httpPrivate.post(
          `${API_BACKEND}/booking/confirm-booking`,
          {
            orderId,
          }
        );

        //navigate(`/thankyou/${orderId}`);
        console.log(data);
      } catch (err) {
        console.log("error confirmation", err);
      } finally {
        navigate(`/thankyou/${orderId}`);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="">
      <div className="container">
        <Loading text={"loading_payment"} />
      </div>
    </div>
  );
};

export default Confirmation;
