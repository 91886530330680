import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import SetInformation from "./SetInformation";
import ConfirmInformation from "./ConfirmInformation";

import { bookingAction } from "../../store/features/booking/bookingSlice";
import { useSelector, useDispatch } from "react-redux";

/*
================================
Component to Show [Component: SetInformation] then user Will fill the data of the spectator 
then show [Component: Confirmation]

===============================
*/
const MainBooking = ({ stadium_id = null }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Reset Booking Spectator's Information When Component Unmount ( Left Booking Page )
  useEffect(() => {
    //dispatch(bookingAction.setStadiumId(stadium_id));
    return () => {
      dispatch(bookingAction.resetBooking());
    };
  }, []);
  const checkInformation = useSelector(
    (state) => state.booking.checkInformation
  );
  return <>{checkInformation ? <ConfirmInformation /> : <SetInformation />}</>;
};

export default MainBooking;
