import { t } from "i18next";
import ForgotPasswordForm from "../components/forgot-password/ForgotPasswordForm";
import Stepper from "../components/UI/Stepper";

const ForgotPassword = () => {
  return (
    <div className="authentification login">
      <div className="container">
        <h2>{t("how_to_get_ticket.info")}</h2>
        <Stepper />
        <div className="auth-form">
          <h1>{t("form.forgot_password")}</h1>

          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
