const SatimGateway = () => {
  return (
    <form>
      <label> Cardholder </label>
      <input type="text" name="cardholder" />
      <label>cib card</label>
      <input type="text" name="cib" />
      <button type="submit">Confirm</button>
    </form>
  );
};

export default SatimGateway;
