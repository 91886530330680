import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import Form from "../UI/form/Form";
import Field from "../UI/form/Field";
import { login } from "../../services/auth";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const LoginFrom = () => {
  // Translation
  const { t } = useTranslation();
  // Auth hook
  const { dispatch, authAction } = useAuth();
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  // onChange INPUT Handler
  const onChangeHandler = (e) => {
    const newData = { ...form, [e.target.name]: e.target.value };
    setForm(newData);
  };

  // onSubmit FORM Handler

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (form.username.length === 0 || form.password.length === 0)
      return setError(t("errors.invalid_credentials"));

    setIsLoading(true);
    const { user, error } = await login(form);
    console.log(user, error);
    if (error) {
      setError(t(error.message));
    }
    if (user) {
      dispatch(authAction.login(user));
      navigate("/");
    }

    setIsLoading(false);
  };
  return (
    <>
      <Form onSubmit={onSubmitHandler} errorForm={error} isLoading={isLoading}>
        <Field
          label={t("form.username")}
          name="username"
          type="text"
          placeholder="Ex: Aladdin"
          onChange={onChangeHandler}
          value={form.username}
        />
        <Field
          label={t("form.password")}
          name="password"
          type="password"
          placeholder="Your password"
          onChange={onChangeHandler}
          value={form.password}
        />
        {!isLoading && (
          <button className="btn btn-form">{t("call_to_action.login")}</button>
        )}
        {isLoading && <div className="btn btn-form">{t("loading")}</div>}
        <div
          className="cta-forgot-password"
          style={{ textAlign: "right", color: "#000" }}
        >
          <Link to="/forgotPassword">{t("form.forgot_password")}?</Link>
        </div>
      </Form>
      <Link to="/" style={{ textAlign: "center", display: "block" }}>
        {t("back_home")}
      </Link>
    </>
  );
};

export default LoginFrom;
