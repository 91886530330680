import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

import { httpPrivate } from "../services/http";
const useHttpPrivate = () => {
  const { accessToken } = useAuth();

  const refresh = useRefreshToken();

  useEffect(() => {
    console.log(accessToken);
    const requestIntercept = httpPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseIntercept = httpPrivate.interceptors.response.use(
      (response) => {
        console.log("res", response);
        return response;
      },

      async (error) => {
        //! 2-TEST INTERCEP

        //! 2-TEST INTERCEP
        const prevRequest = error?.config;

        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 404) &&
          !prevRequest?.sent
        ) {
          //! 2-TEST INTERCEP

          //! 2-TEST INTERCEP
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          console.log(newAccessToken);
          prevRequest.headers[
            "Authorization"
          ] = `Bearer ${newAccessToken.accessToken}`;
          return httpPrivate(prevRequest);
        }

        return Promise.reject(error);
      }
    );

    // Clean UP
    return () => {
      httpPrivate.interceptors.request.eject(requestIntercept);
      httpPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [accessToken, refresh]);
  return httpPrivate;
};

export default useHttpPrivate;
