import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../../services/http";
const initialState = {
  accessToken: null,
  user: null,
  isLoggedIn: false,
};

export const persistLogin = createAsyncThunk("/auth/persist", async () => {
  try {
    const { data } = await http.get("refreshToken", {
      withCredentials: true,
    });
    console.log(data);
    return data.accessToken;
  } catch (err) {
    console.log(err);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      console.log(payload);
      state.accessToken = payload.accessToken;
      state.isLoggedIn = true;
      state.user = payload.user;
    },
    logout: (state) => {
      state.accessToken = null;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const authAction = authSlice.actions;
export default authSlice;
