import React, { Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import store from "./store";
import App from "./App";

import bootstrap from "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/main.scss";

import { AuthContextProvider } from "./store/auth-context";
import Loading from "./components/Loading";
import useLanguages from "./hooks/useLanguages";
import Cookies from "js-cookie";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr"],
    fallbackLng: "fr",
    lng: "fr",
    debug: false,
    // Options for language detector
    detection: {
      lookupQuerystring: "lang",
      order: ["querystring", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    react: { useSuspense: true },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    interpolation: {
      escapeValue: false, // Prevents HTML entities encoding
    },
  });

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </Provider>
  </BrowserRouter>
);
