import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="event-section">
      <div className="event-wrapper">
        <div>
          <div
            className="upcoming-match-text"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {t("header.description")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
