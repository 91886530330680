import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Loading from "./Loading";
import usePersistLogin from "../hooks/usePersistLogin";
import useHttpPrivate from "../hooks/useHttpPrivate";
import { API_BACKEND } from "../utils/api";

const PersitLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { isLoggedIn, dispatch, authAction } = useAuth();
  const location = useLocation();
  const httpPrivate = useHttpPrivate();

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        const data = await refresh();

        dispatch(authAction.login(data));
        console.log("Dispatched");
      } catch (err) {
        console.log(err);
        console.log(err.response.status);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !isLoggedIn ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  const { isLoggedIn: login, isLoading: load } = usePersistLogin();
  useEffect(() => {
    // Signed Out If Reresh Token Expire

    const signOut = async () => {
      try {
        await refresh();
      } catch (err) {
        console.log("token expire", err);
        if (err?.response?.status === 404) {
          dispatch(authAction.logout());
          await httpPrivate.post(`${API_BACKEND}/auth/logout`);
          // Need to clear cookie from backend also
        }
      }
    };

    if (isLoggedIn) {
      signOut();
    }
  }, [location]);

  return <>{load ? <Loading /> : <Outlet />}</>;
};

export default PersitLogin;
