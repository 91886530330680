import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import useAuth from "../../hooks/useAuth";
import useLanguages from "../../hooks/useLanguages";
import useHttpPrivate from "../../hooks/useHttpPrivate";
import { API_BACKEND } from "../../utils/api";
import DeleteModal from "../UI/modal/DeleteModal";

import {convertDate} from "../../utils/functions"

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EventCard = ({ event }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { currentLangCode } = useLanguages();

  const httpPrivate = useHttpPrivate();
  const langLocale = currentLangCode === "ar" ? "ar_DZ" : currentLangCode;

  const onCancelModal = () => {
    setIsOpen(false);
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const { data } = await httpPrivate.delete(
        `${API_BACKEND}/event/delete/${event.id}`
      );
      toast.success(data.message, {
        position: "bottom-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsOpen(false);
      setTimeout(() => (window.location = "/"), 5000);
    } catch (err) {
      console.log(err);
      setError(err.response.data.error);
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <>
      <div className="col-lg-4">
        <div className="event-card">
          <div className="event-card__image">
            <img
              className="event-card__image-img"
              src={`${API_BACKEND}${event.image}`}
              alt=""
            />
          </div>
          <div className="event-card__content">
            <div className="event-card__content-title">
              <a href="#" className="event-card__content-title-link">
                {event[`name_${currentLangCode}`]}
              </a>
            </div>
            <div className="event-card__content-details">
              
                {user?.role === "admin" && (
                  <div className="event-card__content-category">
                    <Link
                      className="event-card__content-event-cta event-cta-modify"
                      to={`/dashboard/editEventAndTicket/${event.id}`}
                    >
                      {t("call_to_action.modify")}
                    </Link>
                    <button
                      className="btn event-card__content-event-cta event-cta-delete"
                      onClick={() => setIsOpen(true)}
                    >
                      {t("call_to_action.delete")}
                    </button>
                    </div>
                )}
              
              <div className="event-card__content-date">
                <div><i class="fa-solid fa-calendar-days"></i>{t("event_start_date", {date: `${convertDate(event.start_date).year}/${convertDate(event.start_date).month}/${convertDate(event.start_date).day}`, time: `${convertDate(event.start_date).hours}:${convertDate(event.start_date).minutes}`})}</div>
                <div><i class="fa-solid fa-calendar-days"></i> {t("event_end_date", {date: `${convertDate(event.end_date).year}/${convertDate(event.end_date).month}/${convertDate(event.end_date).day}`, time: `${convertDate(event.end_date).hours}:${convertDate(event.end_date).minutes}`})}</div>
              </div>
            </div>
            <div className="event-card__content-text">
              {event[`description_${currentLangCode}`]}
            </div>
            <div className="event-card__content-details">
              <div className="event-card__content-details-information">
                <div className="event-card__content-seats-available">
                  <i class="fa-solid fa-hourglass-half"></i>{" "}
                  {t("available_seats")}: {event.Tickets[0].quantity}
                </div>

                <div className="event-card__content-price">
                  <i class="fa-solid fa-money-bill"></i>{" "}
                  {event.Tickets[0].price} {t("DA")}
                </div>
              </div>
              <Link to={`/booking/${event.id}`} className="btn btn-primary">
                {t("buy_ticket")}
              </Link>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      <DeleteModal
        event={event}
        isOpen={isOpen}
        isDeleting={isDeleting}
        onCancel={onCancelModal}
        onDelete={onDelete}
        error={error}
      />
    </>
  );
};

export default EventCard;
