import Joi from "joi";
import i18next from "i18next";

const schema = {
  first_name: Joi.string()
    .trim()
    .min(3)
    .max(50)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.min":
          case "any.required":
            err.message = i18next.t("validation.first_name_limit_min", {
              limit: err.local.limit,
            });
            break;
          case "string.max":
            err.message = i18next.t("validation.first_name_limit_min", {
              limit: err.local.limit,
            });
            break;
          default:
            break;
        }
      });
      return errors;
    }),

  // Last Name
  last_name: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          case "string.min":
            err.message = i18next.t("validation.last_name_limit_min", {
              limit: err.local.limit,
            });
            break;

          case "string.max":
            err.message = i18next.t("validation.last_name_limit_max", {
              limit: err.local.limit,
            });
            break;

          default:
            break;
        }
      });
      return errors;
    }),

  //Telephone
  telephone: Joi.string()
    .trim()
    .min(10)
    .max(10)
    .pattern(new RegExp("^(06|07|05)[0-9]*$"))
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "string.min":
          case "string.max":
          case "string.pattern.base":
            err.message = i18next.t("validation.telephone");
            break;
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          default:
            break;
        }
      });

      return errors;
    }),

  //email
  email: Joi.string()
    .min(2)
    .max(100)
    .email({ tlds: { allow: false } })
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "string.min":
          case "string.max":
          case "string.email":
            err.message = i18next.t("validation.email");
            break;
          case "any.required":
            err.message = i18next.t("valdiation.email");
            break;
          default:
            break;
        }
      });

      return errors;
    }),
  // Username
  username: Joi.string()
    .trim()
    .min(3)
    .max(30)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "string.min":
            err.message = i18next.t("validation.username_limit_min", {
              limit: err.local.limit,
            });
            break;
          case "string.max":
            err.message = i18next.t("validation.username_limit_max", {
              limit: err.local.limit,
            });
            break;
          case "any.required":
            err.message = i18next.t("valdiation.required");
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  //Password
  password: Joi.string()
    .trim()
    .min(5)
    .max(100)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
          case "string.min":
            err.message = i18next.t("validation.password_limit_min", {
              limit: err.local.limit,
            });
            break;
          case "string.max":
            err.message = i18next.t("validation.password_limit_max", {
              limit: err.local.limit,
            });
            break;
          case "any.required":
            err.message = i18next.t("validation.required");
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  //Repeat Password
  repeatPassword: Joi.string()
    .valid(Joi.ref("password"))

    .error((errors) => {
      errors.forEach((err) => {
        console.log(err);
        switch (err.code) {
          case "string.empty":
          case "any.only":
            err.message = i18next.t("validation.repeat_password");
            break;

          default:
            break;
        }
      });
      return errors;
    }),
  /* repeatPassword: Joi.any()
    .valid(password)
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "any.only":
          case "any.required":
            err.message =
              "multilang[lang].validationUser.invalidRepeatPassword";
            break;
          default:
            break;
        }
      });
      return errors;
    })*/
};

const validateForm = (data) => {
  const schema = Joi.object({
    ...schema,
    repeatPassword: Joi.any()
      .valid(data["password"])
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "any.only":
            case "any.required":
              err.message =
                "multilang[lang].validationUser.invalidRepeatPassword";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  });
  const options = { abortEarly: false };
  const { error } = schema.validate(data, options);
};

const validateProprety = (name, value, repeatPassword) => {
  let schema;
  // Repeat password
  let password;

  console.log(Joi.ref("password"));

  schema = Joi.object({ [name]: schema[name] });

  const options = { abortEarly: false };
  const { error } = schema.validate({ [name]: value }, options);
  console.log(error);
  if (!error) return { [name]: false };

  return { [name]: error.details[0].message };
};

// Register User
const validateRegisterUser = (
  first_name,
  last_name,
  telephone,
  username,
  email,
  password,
  repeatPassword
) => {
  const schema = Joi.object({
    // First Name
    first_name: Joi.string()
      .trim()
      .min(3)
      .max(50)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validationUser.first_name.stringEmpty;";
              break;
            case "string.min":
              err.message =
                "multilang[gdationUser.first_name.stringMin(err.local.limit)";
              break;
            case "string.max":
              err.message =
                "multilang[lang].validationUser.first_name.stringMax(err.local.limit)";
              break;
            case "any.required":
              err.message =
                "multilang[lang].validationUser.first_name.required";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    // Last Name
    last_name: Joi.string()
      .trim()
      .min(3)
      .max(30)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validationUser.last_name.stringEmpty";
              break;
            case "string.min":
              err.message =
                "multilang[lang].validationUser.last_name.stringMin(err.local.limit)";
              break;
            case "string.max":
              err.message =
                "multilang[ lang].validationUser.last_name.stringMax(err.local.limit)";
              break;
            case "any.required":
              err.message = "multilang[lang].validationUser.last_name.required";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    //Telephone
    telephone: Joi.string()
      .trim()
      .min(10)
      .max(10)
      .pattern(new RegExp("^(06|07|05)[0-9]*$"))
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validationUser.telephone.stringEmpty";
            case "string.min":
            case "string.max":
            case "string.pattern.base":
              err.message = err.message =
                "multilang[lang].validationUser.telephone.pattern";
              break;
            case "any.required":
              err.message = err.message =
                "multilang[lang].validationUser.telephone.required";
              break;
            default:
              break;
          }
        });

        return errors;
      }),

    //email
    email: Joi.string()
      .min(2)
      .max(100)
      .email()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
            case "any.required":
              err.message = "multilang[lang].validationUser.email.stringEmpty";
              break;
            case "string.min":
            case "string.max":
            case "string.email":
              err.message = "multilang[lang].validationUser.email.invalidEmail";
              break;

            default:
              break;
          }
        });

        return errors;
      }),
    // Username
    username: Joi.string()
      .trim()
      .min(3)
      .max(30)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validationUser.username.stringEmpty";
              break;
            case "string.min":
              err.message = "u";
              break;
            case "string.max":
              err.message = "u";
              break;
            case "any.required":
              err.message =
                "multilang[lang].validation.registerUser.username.required";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    //Password
    password: Joi.string()
      .trim()
      .min(5)
      .max(100)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validation.registerUser.password.stringEmpty";
              break;
            case "string.min":
              err.message = "u";
              break;
            case "string.max":
              err.message = "p";
              break;
            case "any.required":
              err.message =
                "multilang[lang].validation.registerUser.password.required";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    //Repeat Password
    repeatPassword: Joi.any()
      .valid(password)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "any.only":
            case "any.required":
              err.message =
                "multilang[lang].validation.registerUser.invalidRepeatPassword";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  });

  const errors = [];
  try {
    const validationResult = schema.validate(
      {
        first_name,
        last_name,
        telephone,
        username,
        email,
        password,
        repeatPassword,
      },
      { abortEarly: false }
    );

    validationResult.error?.details?.forEach((err) => {
      errors.push({ path: err.path[0], message: err.message });
    });
  } catch (err) {
    errors.push({ path: "any", message: "Something wrong happened" });
  }

  return errors;
};

// Validate First Name
const first_name = (first_name) => {
  const schema = Joi.object({
    first_name: Joi.string()
      .trim()
      .min(3)
      .max(50)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.code) {
            case "string.empty":
              err.message =
                "multilang[lang].validation.registerUser.first_name.stringEmpty;";
              break;
            case "string.min":
              err.message =
                "multilang[gdation.registerUser.first_name.stringMin(err.local.limit)";
              break;
            case "string.max":
              err.message =
                "multilang[lang].validation.registerUser.first_name.stringMax(err.local.limit)";
              break;
            case "any.required":
              err.message =
                "multilang[lang].validation.registerUser.first_name.required";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  });

  const errors = [];
  try {
    const validationResult = schema.validate(
      {
        first_name,
      },
      { abortEarly: false }
    );

    validationResult.error?.details?.forEach((err) => {
      errors.push({ path: err.path[0], message: err.message });
    });
  } catch (err) {
    errors.push({ path: "any", message: "Something wrong happened" });
  }

  return errors;
};

export { validateRegisterUser, first_name, validateProprety, schema };
