import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logout } from "../../../services/auth";

const ColumnLoggedIn = () => {
  const { t } = useTranslation();

  const logoutHandler = async (e) => {
    e.preventDefault();
    await logout();
  };
  return (
    <div className="nav-column-3">
      <div className="links">
        <ul>
          <li>
            <Link className="styled-link link-style-2" to="/my-tickets">
              {t("menu.my_tickets")}
            </Link>
          </li>
          <li>
            <button
              onClick={logoutHandler}
              className="styled-link link-style-1"
              to="/"
            >
              {t("menu.logout")}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ColumnLoggedIn;
