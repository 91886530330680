import { useState, useEffect, useRef } from "react";
import i18next from "i18next";
import ReactTooltip from "react-tooltip";
import cookies from "js-cookie";

import languages from "../../../i18n/languages";
import useLanguages from "../../../hooks/useLanguages";

const ColumnTwo = () => {
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  /*const [currentLangCode, setCurrentLangCode] = useState(
    cookies.get("i18next")
  );
  const [currentLang, setCurrentLang] = useState(
    languages.find((lang) => lang.code === currentLangCode)
  );*/

  const { currentLang, currentLangCode, disptach, langAction, setLang } =
    useLanguages();
  const dropMenuRef = useRef();

  // const currentLangCode = cookies.get("i18next");
  //const currentLang = languages.find((lang) => lang.code === currentLangCode);

  /*useEffect(() => {
    const currentLang = languages.find(
      (lang) => lang.code === cookies.get("i18next")
    );
    document.body.dir = currentLang.dir;

    console.log("change lang");
  }, [currentLangCode]);*/

  /*useEffect(() => {
    const checkIfClickedOutside = (e) => {
      console.log(
        toggleSubMenu,
        dropMenuRef.current,
        dropMenuRef.current.contains(e.target)
      );
      if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
        setToggleSubMenu(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, []);*/
  return (
    <div className="nav-column-2">
      <div className="flags">
        {languages.map(({ code, name, dir, country_code, img }) => (
          <span key={country_code}>
            <a
              data-tip
              data-for={country_code}
              onClick={() => {
                //setActiveLang(code);
                setLang(code);
                // i18next.changeLanguage(code);
              }}
            >
              <img
                className={currentLangCode === code ? "active" : ""}
                src={img}
                alt={name}
              />
            </a>
            <ReactTooltip
              id={country_code}
              place="bottom"
              type="light"
              effect="solid"
            >
              <span>{name}</span>
            </ReactTooltip>
          </span>
        ))}
      </div>
      {/* <div className="links">
        <ul>
          <li ref={dropMenuRef}>
            <span
              style={{ cursor: "pointer" }}
              className="normal-link"
              onClick={(e) => setToggleSubMenu((prev) => !prev)}
            >
              <i class="fa-solid fa-language"></i> Language{" "}
              <i class="fa-solid fa-caret-down"></i>
            </span>
            <ul
              className={
                toggleSubMenu ? "sub-menu shadow show" : "sub-menu shadow"
              }
            >
              <li>English</li>
              <li>Francais</li>
              <li>العربية</li>
            </ul>
          </li>
        </ul>
      </div>*/}
    </div>
  );
};

export default ColumnTwo;
