import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import useLanguages from "../../../hooks/useLanguages";

const DeleteModal = ({
  onDelete,
  onCancel,
  isOpen,
  isDeleting,
  event,
  error,
}) => {
  //const [isOpen, setIsOpen] = useState(false);
  const { currentLangCode } = useLanguages();
  const modalDivRef = useRef(null);
  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete();
    //setIsOpen(false);
  };

  return (
    <>
      {
        <motion.div
          id={event.id}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: isOpen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          ref={modalDivRef}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            style={{
              backgroundColor: "white",
              padding: "30px 50px",
              borderRadius: "0.5rem",
              boxShadow: "0 0 1rem rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {error && (
              <div className="alert alert-danger">{t(error.message)}</div>
            )}
            <h4 style={{ fontWeight: "bold" }}>{t("delete_event_message")}</h4>
            <p style={{ fontWeight: 600 }}>
              {t("ask_delete_event")}{" "}
              <span style={{ fontWeight: "bold" }}>
                "{event[`name_${currentLangCode}`]}" ?
              </span>
              . <br /> {t("cant_undo_delete_event")}
            </p>

            <div style={{ display: "flex", gap: "1rem" }}>
              {!isDeleting && (
                <button className="btn btn-danger" onClick={handleDelete}>
                  {t("call_to_action.delete")}
                </button>
              )}
              {isDeleting && (
                <div className="btn btn-danger">{t("loading")}</div>
              )}
              <button className="btn btn-success" onClick={() => onCancel()}>
                {t("call_to_action.cancel")}
              </button>
            </div>
          </motion.div>
        </motion.div>
      }
    </>
  );
};

export default DeleteModal;
