import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import "moment/min/locales.min";

// Components
import MainBooking from "../components/booking/MainBooking";
import NavbarResponisve from "../components/UI/navbar/NavbarResponsive";
import Loading from "../components/Loading";

// Hooks
import useLanguages from "../hooks/useLanguages";

// Services
import { getEventById } from "../services/event";
import { bookingAction } from "../store/features/booking/bookingSlice";
import useHttpPrivate from "../hooks/useHttpPrivate";

import { API_BACKEND } from "../utils/api";
import { convertDate } from "../utils/functions";

const Booking = () => {
  // Translation
  const { t } = useTranslation();
  const { currentLangCode } = useLanguages();

  // Booking Store
  const dispatch = useDispatch();
  const { totalPrice } = useSelector((state) => state.booking);

  // Routing
  const { id } = useParams();
  const navigate = useNavigate();

  // Http private Auth
  const httpPrivate = useHttpPrivate();

  // States
  const [event, setEvent] = useState();
  const [bookingClosed, setBookingClosed] = useState();
  const [loading, setLoading] = useState(true);

  // Methods

  // Check if booking closed

  /* const booking_closed = (sum_quantity, date_event) => {
    const compare_date =
      new Date().getTime() >=
      new Date(date_event.date + " " + date_event.hour).getTime();

    return parseInt(sum_quantity) === 0 || compare_date;
  };*/

  useEffect(() => {
    const langLocale = currentLangCode === "ar" ? "ar_DZ" : currentLangCode;
    moment.locale(langLocale);

    const fetchData = async () => {
      try {
        const { data } = await httpPrivate.get(`${API_BACKEND}/event/${id}`);

        setEvent(data.event);

        //Dispatch Total Price and Ticket information
        dispatch(bookingAction.setTicket(data.event.Tickets[0]));
        dispatch(bookingAction.setTotalPrice(data.event.Tickets[0].price));
        setLoading(loading);
        console.log(data);
      } catch (err) {
        navigate("/login");
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentLangCode]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <NavbarResponisve />
      <div className="booking">
        <div className="container">
          {bookingClosed && (
            <div className="alert alert-warning">{t("closed_event")}</div>
          )}
          <div className="row">
            {!bookingClosed && (
              <div className="col-md-7">
                <h1 className="title">ACHAT TICKET</h1>
                <h4 className="sub-title">
                  Please fill all formualire proprely to get your ticket
                </h4>
                <div className="booking-form">
                  <MainBooking />
                </div>
              </div>
            )}
            <div className={bookingClosed ? "col-md-12" : "col-md-5"}>
              <h3>{t("ticket_information")}</h3>
              <h4 className="sub-title">
                All the information about the ticket!
              </h4>
              <div className="ticket-information">
                <h2>{event[`name_${currentLangCode}`]}</h2>
                <p>{event[`description_${currentLangCode}`]}</p>
                <h3>
                  <i class="fa-solid fa-calendar-days"></i>{" "}
                  {t("event_start_date", {
                    date: `${convertDate(event.start_date).year}/${
                      convertDate(event.start_date).month
                    }/${convertDate(event.start_date).day}`,
                    time: `${convertDate(event.start_date).hours}:${
                      convertDate(event.start_date).minutes
                    }`,
                  })}
                </h3>
                <h3>
                  <i class="fa-solid fa-calendar-days"></i>{" "}
                  {t("event_end_date", {
                    date: `${convertDate(event.end_date).year}/${
                      convertDate(event.end_date).month
                    }/${convertDate(event.end_date).day}`,
                    time: `${convertDate(event.end_date).hours}:${
                      convertDate(event.end_date).minutes
                    }`,
                  })}
                </h3>

                <h3>
                  <i class="fa-solid fa-location-dot"></i> {t("location")}:{" "}
                  <span>Palais</span>
                </h3>

                {!bookingClosed && (
                  <div className="ticket-price">
                    <span>{t("total_price")}:</span>
                    <span>
                      {totalPrice}.00 {t("DA")}
                    </span>
                  </div>
                )}
              </div>
              <div class="satim-call">
                <img src="https://satim.dz/images/cal.png" alt="" />
                <img
                  src="https://certweb.satim.dz/img/logo-interoperabilite-final.png"
                  width="65px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
