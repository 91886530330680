import { useState, useRef } from "react";
import useHttpPrivate from "../../hooks/useHttpPrivate";

import Form from "../../components/UI/form/Form";
import NavbarResponsive from "../../components/UI/navbar/NavbarResponsive";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BACKEND } from "../../utils/api";
import { useTranslation } from "react-i18next";

const CreateEvent = () => {
  const [name_en, setNameEn] = useState("");
  const [name_fr, setNameFr] = useState("");
  const [name_ar, setNameAr] = useState("");
  const [description_en, setDescriptionEn] = useState("");
  const [description_fr, setDescriptionFr] = useState("");
  const [description_ar, setDescriptionAr] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const[start_date, setStartDate] = useState("");
  const[start_time, setStartTime] = useState("");

  const [end_date, setEndDate] = useState("");
  const [end_time, setEndTime] = useState("");

  const [image, setImage] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  const imageFileRef = useRef("imageFileRef");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const httpPrivate = useHttpPrivate();

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    formData.append("name_ar", name_ar);
    formData.append("name_fr", name_fr);
    formData.append("name_en", name_en);
    formData.append("description_en", description_en);
    formData.append("description_fr", description_fr);
    formData.append("description_ar", description_ar);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("image", image);
    formData.append("quantity", quantity);
    formData.append("price", price);

    //const response = await createEvent(formData);

    try {
      const { data } = await httpPrivate.post(
        `${API_BACKEND}/event/create-event-ticket`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(data.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // Reset States
      setNameEn("");
      setNameFr("");
      setNameAr("");
      setDescriptionEn("");
      setDescriptionFr("");
      setDescriptionAr("");
      //setDate("");
     // setTime("");
     setStartDate("")
     setEndDate("")
      setImage("");
      setQuantity("");
      setPrice("");
      imageFileRef.current.value = "";
      setError("");
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        const { message } = err.response.data;
        toast.error(message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setError(err.response.data.message);
      } else {
        toast.error("Something unkonw happen", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <NavbarResponsive />
      <div className="booking">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h1 className="title">{t("add_event.create_event_title")}</h1>
              <h4 className="sub-title">Please fill all form proprely</h4>
              <div>
                <Form onSubmit={handleSubmit} errorForm={error.message}>
                  <div className="spectator-field">
                    <h2>{t("add_event.info_event")}</h2>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_en")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_en}
                          onChange={(e) => setNameEn(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_fr")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_fr}
                          onChange={(e) => setNameFr(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.event_name_ar")}:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_ar}
                          onChange={(e) => setNameAr(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_en")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_en}
                          onChange={(e) => setDescriptionEn(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_fr")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_fr}
                          onChange={(e) => setDescriptionFr(e.target.value)}
                          required
                        ></textarea>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          {t("add_event.description_ar")}:
                        </label>
                        <textarea
                          className="form-control"
                          value={description_ar}
                          onChange={(e) => setDescriptionAr(e.target.value)}
                          required
                        ></textarea>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label">
                            تاريخ بداية الحدث
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={start_date}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            تاريخ نهاية الحدث
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            value={end_date}
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      
                      <div className="row">
                        <label forHtml="formFile" class="form-label">
                          {t("add_event.upload_image")}
                        </label>
                        <input
                          class="form-control"
                          type="file"
                          id="formFile"
                          onChange={(e) => setImage(e.target.files[0])}
                          ref={imageFileRef}
                          required
                        />
                      </div>
                      <h2>{t("add_event.info_ticket")}</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label">
                            {t("add_event.nbr_ticket")}:{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ex: 100"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            {t("add_event.price")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ex: 100"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {isLoading && (
                    <div className="btn btn-primary">{t("loading")}</div>
                  )}
                  {!isLoading && (
                    <button className="btn btn-primary">
                      {t("add_event.cta_add_event")}
                    </button>
                  )}
                </Form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEvent;
