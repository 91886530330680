const Field = ({ label, type, name, placeholder, value, onChange, error }) => {
  return (
    <div className="mb-3">
      <label forhtml={name} className="form-label">
        {label}
      </label>
      <input
        type={type}
        className={
          error ? "is-invalid form-control-error form-control" : "form-control"
        }
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Field;
