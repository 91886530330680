import { t } from "i18next";
import ResetPasswordForm from "../components/forgot-password/ResetPasswordForm";
import Stepper from "../components/UI/Stepper";

const ResetPassword = () => {
  return (
    <div className="authentification login">
      <div className="container">
        <h2>{t("how_to_get_ticket.info")}</h2>
        <Stepper />
        <div className="auth-form">
          <h1>{t("form.reset_password")}</h1>

          <ResetPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
